import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Servise.css";

const Service = ({ service, index }) => {
  const { id, name, img, description } = service;
  const navigate = useNavigate();
  const navigateToServiceDetail = (id) => {
    navigate(`/service/${id}`);
  };

  // Generate a unique background color for each course
  const backgroundColors = [
    "#FFFFFF",
    "#F7F8FC",
    // "#212429",
    // "#030301",
    // "#142247",
    // "#0F0B16",
    // "#131836",
    // "#00103D",
    // "#010101",
    // "#00103D",
  ]; // Example colors, you can add more
  const backgroundColor = backgroundColors[index % backgroundColors.length];

  return (
    <Col lg="10" className="text-center mb-5">
      <div
        className="service-container p-2"
        style={{
          backgroundColor,
          // background: "linear-gradient(45deg, blue, red)",
          color: "#0D161B",
        }}
      >
        <Row className="align-items-center justify-content-center">
          {index % 2 === 0 ? (
            <>
              <Col lg="6">
                <img className="w-100 rounded service-image" src={img} alt="" />
              </Col>
              <Col lg="6" className="position-relative">
                <div className="text-start">
                  <h1 style={{ fontWeight: "bold" }} className="pt-2">
                    {name}
                  </h1>

                  <p style={{ fontWeight: "bold" }}>
                    {description.slice(0, 180)}
                  </p>

                  <div className="py-2">
                    <Button
                      onClick={() => navigateToServiceDetail(id)}
                      variant="primary

"
                      size="lg"
                      style={{
                        fontSize: "1.01rem",
                        textAlign: "justify",
                        fontWeight: "bold",
                        // background: "#FFA500",

                        color: "white",
                      }}
                    >
                      Explore {name} 🚀
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col
                lg="6"
                md={{ order: "first" }}
                xs={{ order: "last" }}
                className="position-relative"
              >
                <div className="text-start">
                  <h1 style={{ fontWeight: "bold" }} className="pt-2">
                    {name}
                  </h1>

                  <p style={{ fontWeight: "bold" }}>
                    {description.slice(0, 480)}
                  </p>
                  <div className="py-2">
                    <Button
                      onClick={() => navigateToServiceDetail(id)}
                      variant="primary"
                      size="lg"
                      style={{
                        fontSize: "1.01rem",
                        textAlign: "justify",
                        fontWeight: "bold",
                      }}
                    >
                      Explore {name} 🚀
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md={{ order: "last" }}
                xs={{ order: "first" }}
                className="d-flex justify-content-center"
              >
                <img className="w-100 rounded service-image" src={img} alt="" />
              </Col>
            </>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default Service;
