import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Banner.css";

const Banner = () => {
  return (
    <article className="banner-style">
      <Container fluid="md">
        <Row>
          <Col
            md={8}
            className="my-auto mx-auto px-2"
            style={{
              textAlign: "start",
            }}
          >
            <h1 className="pb-3 fw-bolder">
              Basic Programmer: Provides Custom Web Apps for You!
            </h1>
            <h5 className="pb-3">
              🚀 Web development services: Portfolio, Personal,
              Blog,Educational, Corporate, Charity, News, E-commerce.
            </h5>
            <h5 className="pb-3">
              ✅ Free Training Coming Soon: How to become a developer in 90
              days, Even as a Complete Beginner!"
            </h5>
            <h5 className="pb-3">
              🛠️ Technologies you need (react, redux, firebase, and more)
            </h5>
            <h5 className="pb-3">
              🏫 How our exclusive Frontend Mastery Coding Bootcamp can help you
            </h5>
            <h5 className="pb-3">
              👨‍💻 What it takes to get job offers from big tech companies
            </h5>
          </Col>
          <Col md={4} className="my-auto text-center">
            <Button variant="primary" size="lg" className="fw-bold">
              <a
                className="text-decoration-none text-white"
                href="https://www.youtube.com/c/BasicProgrammer"
              >
                Yes, I want this FREE Training 🚀
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Banner;
