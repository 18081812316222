import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Course.css";

const Course = ({ course, index }) => {
  const { id, name, img, description, price } = course;
  const navigate = useNavigate();
  const navigateToServiceDetail = (id) => {
    navigate(`/course/${id}`);
  };

  // Generate a unique background color for each course
  const backgroundColors = ["#F7F8FC", "#ffffff", "#F7F8FC"]; // Example colors, you can add more
  const backgroundColor = backgroundColors[index % backgroundColors.length];

  return (
    <>
      <Col lg="4" className="text-center mb-2 ">
        <Row className="course-container text-black p-0 border mx-1 ">
          {/* <Row className="justify-content-md-center"> */}
          {index % 2 === 0 ? (
            <>
              <Col lg="12" className="mx-auto px-0">
                <img className="w-100 rounded course-image" src={img} alt="" />

                <div className="text-center py-3 px-3">
                  <h3 style={{ fontWeight: "bold" }} className="pt-0">
                    {name}
                  </h3>
                  <h5 style={{ fontWeight: "bold" }}>
                    One Time Payment: ${price}
                  </h5>
                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    {description.slice(0, 70)}..
                  </p>
                  <div className="mt-3 text-center">
                    <Button
                      onClick={() => navigateToServiceDetail(id)}
                      variant="primary"
                      size="lg"
                      style={{
                        fontSize: "1.1rem",
                        textAlign: "justify",
                        fontWeight: "bold",
                      }}
                    >
                      Explore {name} 🚀
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col lg="12" className="mx-auto px-0">
                <img className="w-100 rounded course-image" src={img} alt="" />
                <div className="text-center py-3 px-3">
                  <h3 style={{ fontWeight: "bold" }} className="pt-0">
                    {name}
                  </h3>
                  <h5 style={{ fontWeight: "bold" }}>
                    One Time Payment: ${price}
                  </h5>
                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    {description.slice(0, 70)}..
                  </p>
                  <div className="mt-3 text-center">
                    <Button
                      onClick={() => navigateToServiceDetail(id)}
                      variant="primary"
                      size="lg"
                      style={{
                        fontSize: "1.1rem",
                        textAlign: "justify",
                        fontWeight: "bold",
                      }}
                    >
                      Explore {name}🚀
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          )}
          {/* </Row> */}
        </Row>
      </Col>
    </>
  );
};

export default Course;
