import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import hcsIct from "../../../images/Hsc-ict.jpeg";
import Course from "../Course/Course";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    fetch("services.json")
      .then((res) => res.json())
      .then((data) => setCourses(data));
  }, []);

  return (
    <div>
      <Container fluid="md" className="rounded pt-3">
        <Row className="justify-content-md-center">
          <Col lg="8">
            <h1
              style={{ fontWeight: "bold" }}
              className="text-center text-black mb-5"
            >
              Do you want to be a highly-paid developer? Explore our programs
              coming soon👇
            </h1>
          </Col>
        </Row>
        <Row lg="12" className="justify-content-md-center py-2">
          {courses.slice(8, 11).map((course, index) => (
            <Course key={course.id} course={course} index={index}></Course>
          ))}
        </Row>
        <Row className="justify-content-md-center">
          <Col
            lg="8"
            className="text-center px-0 text-black my-5"
            // style={{
            //   background:
            //     "linear-gradient(180deg, #222900, #455E05, #252E03, #090D25 )",
            // }}
          >
            <img className="w-100 rounded" src={hcsIct} alt="" />
            <div className="mb-3" style={{ textAlign: "justify" }}>
              <Accordion defaultActiveKey="0" className=" bg-transparent">
                <Accordion.Item eventKey="1" className="bg-transparent">
                  <Accordion.Header>
                    {" "}
                    <h5>What will this course cover?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p style={{ textAlign: "justify" }}>
                      <strong>Number systems and digital devices </strong>
                      Number systems are the way that we represent numbers.
                      Computers use a binary number system, which means that
                      they use only two digits, 0 and 1. This is because
                      computers are made up of electronic switches, which can be
                      either on or off. Digital devices are electronic devices
                      that use digital circuits to process and transmit data.
                      Digital circuits are made up of logic gates, which are
                      electronic components that can perform basic operations
                      such as AND, OR, and NOT.
                      <strong> Web design and HTML </strong>
                      Web design is the process of creating and maintaining
                      websites. HTML (HyperText Markup Language) is the standard
                      markup language for creating web pages. HTML is used to
                      define the structure and content of web pages.
                      <strong> Programming languages </strong>
                      Programming languages are used to write computer programs.
                      Computer programs are sets of instructions that tell a
                      computer what to do. There are many different programming
                      languages, each with its own strengths and weaknesses.
                      <strong> Database management systems </strong>
                      Database management systems (DBMSs) are used to store and
                      manage data in a database. A database is a collection of
                      data that is organized in a specific way. DBMSs provide
                      features such as data security, data integrity, and data
                      access control.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <Button
              variant="primary shadow-lg"
              size="lg"
              className="fw-bold"
              href="https://api.whatsapp.com/send?phone=1754675993"
            >
              Explore HSC ICT
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Courses;
