import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import "./Footer.css";
import {
  faFacebook,
  faYoutube,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer-style bg-black">
      <Container className=" pt-3">
        <Row className="justify-content-md-center">
          <Col xs="12" lg="5" className="mt-3 footer-title">
            <div className="text-center" onClick={scrollToTop}>
              {" "}
              {/* Attach onClick event handler here */}
              <img
                style={{
                  height: "50px",
                  border: "2px solid white",
                  borderRadius: "25px",
                }}
                src={logo}
                alt=""
              />
            </div>
            <h5 className="text-center ms-2 my-3 text-white">
              &copy; {new Date().getFullYear()} Basic Programmer
            </h5>
          </Col>

          <Col xs="12" lg="2"></Col>

          <Col xs="12" lg="5" className="mt-3 mx-auto text-center pt-3">
            <Link to="/" className=" text-decoration-none footer-menu">
              Careers
            </Link>

            <Link to="/" className="text-decoration-none footer-menu">
              Resources
            </Link>
            <Link to="/" className="text-decoration-none footer-menu">
              Terms
            </Link>
            <Link to="/" className="text-decoration-none footer-menu">
              Privacy
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs="12" lg="4" className="mx-auto text-center p-2 mb-3">
            <a
              className="text-decoration-none social-icon"
              href="https://web.facebook.com/BasicProgrammerPage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="p-3 text-black fa-2x text-white"
              />
            </a>
            <a
              className="text-decoration-none"
              href="https://www.linkedin.com/company/basic-programmer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="p-3 text-black fa-2x text-white"
              />
            </a>
            <a
              className="text-decoration-none"
              href="https://api.whatsapp.com/send?phone=1754675993"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="p-3 text-black fa-2x text-white"
              />
            </a>
            <a
              className="text-decoration-none"
              href="https://www.youtube.com/c/BasicProgrammer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                className="p-3 text-black fa-2x text-white"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
