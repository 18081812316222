import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Service from "../Service/Service";

const Services = () => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    fetch("services.json")
      .then((res) => res.json())
      .then((data) => setServices(data));
  }, []);

  return (
    <div>
      <Container fluid="md" className="rounded pt-3">
        <Row className="justify-content-md-center">
          <Col lg="8">
            <h1
              style={{ fontWeight: "bold" }}
              className="text-center text-black mb-5"
            >
              Are you looking for a high-quality web application? Explore our
              services 👇
            </h1>
            {/* <h1 className="text-center mb-5">
              Do you want to be a highly-paid developer? Explore our programs 👇
            </h1> */}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          {services.slice(0, 8).map((service, index) => (
            <Service index={index} service={service}></Service>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Services;
