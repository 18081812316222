import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const About = () => {
  return (
    <Container className="text-black" style={{ paddingBottom: "100px" }}>
      <Row>
        <Col
          md={8}
          style={{ textAlign: "justify" }}
          className="my-auto mx-auto mt-5 my-5 text-black"
        >
          <div className="d-flex align-items-center justify-content-center mb-3">
            <img
              className="img-fluid"
              src={"https://i.ibb.co/Jnkpckv/Mosheur-CEO.png"}
              alt=""
            />
          </div>

          <p className="text-justify py-2">
            Hi, I'm Mosheur Rahman. Founder and CEO @ Basic Programmer. I
            started Basic Programmer, A group of students who are learning to
            code through the creation of real-world projects. My mission is
            straightforward: to teach the world how to make things with code.
            <br /> <br />
            Why? Because Dreams + Code = Reality.
            <br /> <br />
            Basic Programmer also provide various types of web application
            services such as portfolio websites, personal websites, education
            websites, corporate websites, e-commerce websites, we create all
            these websites and we create different types of websites according
            to the customer's requirements. <br />
            Finally, I enjoy making things simple for the rest of the world.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
