import React from "react";
import Banner from "../Banner/Banner";
import Reviews from "../Reviews/Reviews";
import Services from "../Services/Services";
import Courses from "../Courses/Courses";
import Discount from "../Discount/Discount";

const Home = () => {
  return (
    <div>
      <Banner></Banner>
      <Discount></Discount>
      <Services></Services>
      <Courses></Courses>
      <Reviews></Reviews>
    </div>
  );
};

export default Home;
